var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card',{staticClass:"mt-2"},[_c('v-app-bar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title')],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getFirewallRules,"data-test":"dataTable-field","item-key":"uid","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberFirewallRules,"options":_vm.pagination,"disable-sort":true},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-icon',{attrs:{"bottom":""}},[_vm._v(" check_circle ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.priority)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action)+" ")]}},{key:"item.source_ip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source_ip)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hostname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('FirewallRuleEdit',{attrs:{"data-test":"firewall-dialog-field-2","firewall-rule":item,"create-rule":false},on:{"update":_vm.refresh}}),_c('FirewallRuleDelete',{attrs:{"id":item.id,"data-test":"firewall-delete-field"},on:{"update":_vm.refresh}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }